<template>
  <v-app id="inspire">
    <Header></Header>
    <v-main>
      <v-container fluid class="page-wrapper">
        <router-view />
      </v-container>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "./header/Header";
import Footer from "./footer/Footer";
export default {
  name: "Layout",

  components: {
    Header,
    Footer
  },

  props: {
    source: String
  }
};
</script>

<style></style>
